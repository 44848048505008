<template>
  <v-card>
    <!-- form header -->
    <CardHeader>
      <template v-slot:left>
        <div class="pa-4">
          <v-card color="#525252" flat height="29px" width="176px">
            <div class="d-flex justify-center align-center">
              <v-icon color="white" size="18">$info</v-icon>
              <div class="font-18px white--text pl-2">
                {{ $t("page_edit_store_title") }}
              </div>
            </div>
          </v-card>
        </div>
      </template>
      <template v-slot:right>
        <div
          class="mr-8 mouse-pointer"
          v-if="formDisplay"
          @click="formDisplay = false"
        >
          <v-icon small>$customChevronUp</v-icon>
        </div>
        <div class="mr-8 mouse-pointer" v-else @click="formDisplay = true">
          <v-icon small>$customChevronDown</v-icon>
        </div>
      </template>
    </CardHeader>
    <!-- main form -->
    <v-expand-transition>
      <validation-observer ref="observer">
        <v-form ref="form" @submit.prevent="submit" v-show="formDisplay">
          <!-- the outer spacing -->
          <v-row no-gutters justify="space-around" class="main-form pt-7 pb-12">
            <!-- left side -->
            <v-col cols="10" xl="5" md="6" lg="5">
              <!-- id -->
              <InputFormElement labelWidth="3" label="ID">
                <p class="font-weight-light text-blue pl-4 mt-4 mb-8 unedited">
                  {{ getShop ? getShop.id : "" }}
                </p>
              </InputFormElement>
              <!-- store name -->
              <InputFormElement
                :important="true"
                labelWidth="3"
                :label="$t('page_new_store_name')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_name')"
                  rules="required:店舗名"
                >
                  <v-text-field
                    dense
                    height="27px"
                    flat
                    outlined
                    full-width
                    placeholder="店舗名"
                    :error-messages="errors"
                    v-model="fields.store_name.value"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
              <!-- phone number -->
              <InputFormElement
                labelWidth="3"
                :important="true"
                :label="$t('page_new_store_telephone_number')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_telephone_number')"
                  rules="required:電話番号|enter_half_width_numbers_hyphens"
                >
                  <v-text-field
                    dense
                    height="27px"
                    flat
                    outlined
                    full-width
                    placeholder="電話番号"
                    v-model="fields.phone_number.value"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
              <!-- email -->
              <InputFormElement
                labelWidth="3"
                :label="$t('page_new_store_email_address')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_email_address')"
                  rules="email"
                >
                  <v-text-field
                    dense
                    height="27px"
                    flat
                    outlined
                    placeholder="メールアドレス"
                    full-width
                    v-model="fields.mail_address.value"
                    :error-messages="errors"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
              <!-- representative -->
              <InputFormElement
                labelWidth="3"
                :label="$t('page_new_store_representative')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_representative')"
                  rules=""
                >
                  <v-text-field
                    :error-messages="errors"
                    dense
                    height="27px"
                    flat
                    outlined
                    placeholder="代表者"
                    full-width
                    v-model="fields.representative.value"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
            </v-col>
            <v-col cols="10" xl="5" md="6" lg="5">
              <!-- postal code -->
              <InputFormElement
                labelWidth="3"
                :important="true"
                :label="$t('page_new_store_postal_codes')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_postal_codes')"
                  rules="required:郵便番号"
                >
                  <v-text-field
                    dense
                    height="27px"
                    flat
                    outlined
                    placeholder="郵便番号"
                    full-width
                    :error-messages="errors"
                    v-model="fields.postal_code.value"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
              <!-- prefectures -->
              <InputFormElement
                labelWidth="3"
                :label="$t('page_new_store_prefectures')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_prefectures')"
                  rules=""
                >
                  <v-select
                    :error-messages="errors"
                    :items="listPrefectures"
                    dense
                    item-text="name"
                    item-value="id"
                    outlined
                    v-model="fields.prefectures.value"
                  ></v-select>
                </validation-provider>
              </InputFormElement>
              <!-- city Address -->
              <InputFormElement
                labelWidth="3"
                :label="$t('page_new_store_city_address')"
              >
                <v-text-field
                  dense
                  outlined
                  placeholder="市区町村・番地"
                  v-model="fields.city_address.value"
                ></v-text-field>
              </InputFormElement>
              <!-- bulding number room number -->
              <InputFormElement
                labelWidth="3"
                :label="$t('page_new_store_room_number')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_room_number')"
                  rules=""
                >
                  <v-text-field
                    dense
                    :error-messages="errors"
                    placeholder="建物名・部屋番号等"
                    outlined
                    v-model="fields.building_name_room_number.value"
                  ></v-text-field>
                </validation-provider>
              </InputFormElement>
              <InputFormElement
                class="mt-4"
                labelWidth="3"
                :label="'アプリ登録日'"
              >
                <p class="font-weight-light pl-4 unedited">
                  {{ getShop.registration_date | formatDate("YYYY-MM-DD") }}
                </p>
              </InputFormElement>
            </v-col>
          </v-row>
          <v-row no-gutters justify="space-around" class="pt-11">
            <!-- left side -->
            <v-col cols="10" xl="5" md="6" lg="5">
              <!-- company form -->
              <InputFormElement
                :label="$t('page_new_store_company_form')"
                labelWidth="3"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_company_form')"
                  rules=""
                >
                  <v-select
                    :error-messages="errors"
                    :items="listCompanyForm"
                    dense
                    outlined
                    item-text="value"
                    item-value="id"
                    v-model="fields.company_type.value"
                  ></v-select>
                </validation-provider>
              </InputFormElement>
              <InputFormElement
                class="my-2"
                :label="$t('page_new_store_fiscal_year_start_month')"
                labelWidth="3"
              >
                <p class="pl-4 font-weight-light unedited">
                  {{ `${getShop.start_of_fiscal_year}月` }}
                </p>
              </InputFormElement>
            </v-col>
            <v-col cols="10" xl="5" md="6" lg="5">
              <InputFormElement
                labelWidth="3"
                :important="true"
                :label="$t('page_new_store_registeration_date')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_registeration_date')"
                  rules="required:登記日"
                >
                  <DatePicker
                    type="YYYY-MM"
                    v-model="fields.registration_date.value"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-text-field
                        v-on="on"
                        v-bind="attrs"
                        dense
                        :error-messages="errors"
                        outlined
                        v-model="fields.registration_date.value"
                        :placeholder="
                          $t('page_new_store_registeration_date_placeholder')
                        "
                      ></v-text-field>
                    </template>
                  </DatePicker>
                </validation-provider>
              </InputFormElement>
              <!-- employees number -->
              <InputFormElement
                labelWidth="3"
                :label="$t('page_new_store_number_of_employees')"
              >
                <validation-provider
                  v-slot="{ errors }"
                  :name="$t('page_new_store_number_of_employees')"
                  rules=""
                >
                  <v-select
                    :error-messages="errors"
                    :items="listNumberOfEmployees"
                    dense
                    outlined
                    item-text="value"
                    item-value="id"
                    v-model="fields.number_of_employees.value"
                  ></v-select>
                </validation-provider>
              </InputFormElement>
            </v-col>
          </v-row>
          <!-- submit buttons -->
          <v-row no-gutters class="pb-9 pt-11" align="center">
            <v-col>
              <span
                @click="deleteShop"
                class="pl-8 text-red font-weight-bold mouse-pointer"
              >
                {{ $t("page_store_registeration_delete") }}
              </span>
            </v-col>
            <v-col class="d-flex justify-center text-light-grey">
              <v-btn
                class="mx-2"
                width="84px"
                height="24px"
                type="submit"
                color="primary"
                >{{ $t("page_new_store_register") }}</v-btn
              >
            </v-col>
            <v-col></v-col>
          </v-row>
        </v-form>
      </validation-observer>
    </v-expand-transition>
  </v-card>
</template>
<script>
import InputFormElement from "@/components/admin/partials/Shops/partials/InputFormElement";
import CardHeader from "@/components/admin/ui/CardHeader";
import DatePicker from "@/components/admin/ui/DatePicker";
import { mapGetters } from "vuex";
import dayjs from "@/plugins/dayjs";
export default {
  components: {
    InputFormElement,
    CardHeader,
    DatePicker
  },
  data() {
    return {
      fields: {
        store_name: {
          value: ""
        },
        mail_address: {
          value: ""
        },
        phone_number: {
          value: ""
        },
        representative: {
          value: ""
        },
        postal_code: {
          value: ""
        },
        prefectures: {
          value: ""
        },
        city_address: {
          value: ""
        },
        building_name_room_number: {
          value: ""
        },
        company_type: {
          value: ""
        },
        number_of_employees: {
          value: ""
        },
        registration_date: {
          type: "date",
          value: dayjs().format("YYYY-MM-DD"),
          locale: "ja",
          date_format: "YYYY-MM-DD",
          date: dayjs().format("YYYY-MM-DD"),
          menu: false
        }
      },
      formDisplay: true,
      loading: false
    };
  },
  computed: {
    ...mapGetters(["getShop"]),
    listPrefectures() {
      return this.$store.getters.getPrefectures;
    },
    listNumberOfEmployees() {
      return this.$store.getters.getMasterData?.number_of_employees;
    },
    listCompanyForm() {
      return this.$store.getters.getMasterData?.company_type;
    }
  },
  watch: {
    getShop: {
      // Check Shop data as API is already called in other parent components
      deep: true,
      handler(val) {
        if (val) {
          this.fillPageData();
        }
      }
    }
  },
  methods: {
    fillPageData() {
      Object.keys(this.fields).forEach(key => {
        if (this.getShop[key]) {
          if (
            key == "number_of_employees" ||
            key == "prefectures" ||
            key == "company_type"
          ) {
            this.fields[key].value = parseInt(this.getShop[key]);
          } else {
            this.fields[key].value = this.getShop[key];
          }
        }
      });
    },
    async deleteShop() {
      const result = await this.$root.confirm
        .open(
          this.$t("page_store_delete_confirmation_alert") +
            "\n" +
            this.$t("delete_confirmation_alert"),
          "Are you sure?",
          { color: "red" }
        )
        .then(response => {
          if (response) {
            this.$store
              .dispatch("SHOP_DELETE", {
                id: this.$route.params.shop_id
              })
              .then(() => {
                this.$store.dispatch("MASTER_GET_ALL").then(() => {
                  this.$router.push({ name: "Shops" });
                });
              });
          }
        });
    },
    submit() {
      this.$refs.observer.validate().then(success => {
        if (!success) {
          this.formStatus = false;
          return;
        }

        let data = {};
        data.start_of_fiscal_year = this.getShop.start_of_fiscal_year;
        data.shop_id = this.$route.params.shop_id;
        Object.keys(this.fields).forEach(key => {
          data[key] = this.fields[key].value;
        });
        this.loading = true;
        this.$store
          .dispatch("SHOP_UPDATE", data)
          .then(response => {
            if (response.status === 200) {
              this.loading = false;
              this.$router.push({ name: "Shops" });
            }
          })
          .finally(() => (this.loading = false));
      });
    }
  }
};
</script>
<style scoped src="./style.scss" lang="scss"></style>
